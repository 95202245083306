jQuery(document).ready(function ($) {


    $('.price_title').on('click', function () {
        $('.page_header').css('z-index', '0');
    });

    $('.available').on('click', function () {
        $('.nav.main_menu').css('z-index', '0');
    });

    $('.available').on('click', function () {
        $('.page_header').css('z-index', '0');
    });

    $('.price_title').on('click', function () {
        $('.qodef-page-footer').addClass('hide_me');
    });

    $('.available').on('click', function () {
        $('.qodef-page-footer').css('z-index', '0');
    });

    $('.close').on('click', function () {
        $('.page_header').css('z-index', '110');
    });

    $('.close').on('click', function () {
        $('nav.main_menu').css('z-index', '110');
    });

    $('.close').on('click', function () {
        $('.qodef-page-footer').removeClass('hide_me');
    });

    $('.btn.btn-default').on('click', function () {
        $('nav.main_menu').css('z-index', '110');
    });

    $('.btn.btn-default').on('click', function () {
        $('.nav.main_menu').css('z-index', '110');
    });


    $('.btn.btn-default').on('click', function () {
        $('.page_header').css('z-index', '110');
    });

    $('.btn.btn-default').on('click', function () {
        $('.qodef-page-footer').removeClass('hide_me');
    });

});